import React from "react";
import { Link } from "react-router-dom";

export default function ProgramCTA({
  img1,
  img1Link,
  img2,
  img2Link,
  title1,
  title2,
}) {
  return (
    <>
      <h3>Programs</h3>
      <div className="row pt-4 pcta">
        <div className="col-md-6">
          <Link to={img1Link}>
            <img src={img1} alt="" className="w-100" />
            <h4>{title1}</h4>
          </Link>
        </div>
        <div className="col-md-6">
          <Link to={img2Link}>
            <img src={img2} alt="" className="w-100" />
            <h4>{title2}</h4>
          </Link>
        </div>
      </div>
    </>
  );
}
