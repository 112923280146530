import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import hbd from "../../Assets/img/hyd_big.png";
import BackButton from "../../Components/BackBtn";

export default function Hyderabad() {
  const colleges = [
    "Osmania University",
    "Andhra University",
    "Kakatiya University",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Hyderabad, India"
            content="These two states are to be considered as one among the emerging
educational destinations for International community. Exceeding with 3500
international students learning in different universities. Andhra Pradesh is
not any less in taking care of the international students. it has come up with
an idea of having a hostel dedicated exclusively for foreign students in
Andhra university."
            img={hbd}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
