import React from "react";
import AdmissionsIcon from "../Assets/img/admissions.svg";
import Admissions from "../Components/Admissions";
import BackButton from "../Components/BackBtn";

export default function AdmissionsView() {
  return (
    <>
      <div className="container ptb-5">
        <div className="d-flex align-items-center gap-4 pb-3">
          <img src={AdmissionsIcon} alt="" />{" "}
          <h3 className="m-0">Admissions for UG/PG/Diploma 2025-26</h3>
        </div>
        <p>
          In many universities, entrance tests and merit are the main factors
          used to determine UG admissions in 2025. Among the most well-liked and
          sought-after courses, it is chosen by thousands of students in
          undergraduate programs such as BA, BSc, BCom, BTech, BBA, LLB, etc. In
          order to open up better opportunities in the future, you can
          specialize in a certain subject and select a UG course based on the
          stream you selected in your 10+2.Merit-based and entrance- based
          admission are the two most widely used entry points for undergraduate
          studies. Popular entrance exams like CUET UG, IPU CET, NEST, NEET and
          others can be taken to gain admission to a reputable college for your
          BA, BSc, or BCom degree. There are various kinds of universities in
          India.
        </p>
        <p>
          Central, State, Deemed, and Private Universities are among the various
          kinds of Indian universities to which you can apply. As a result, each
          university has different requirements for admission, and the procedure
          varies depending on the course you are applying for.
        </p>
        <p>
          In order to comprehend the prerequisites for admission to that
          specific university, you must first choose which one you wish to
          attend. After that, you have to choose a path.
        </p>
        <p>
          The deadline for submitting completed applications is June, while
          admission windows for practically all undergraduate courses open in
          April. The entrance tests will be held as early as December, and some
          colleges may open the application process as early as October.
        </p>
        <p>
          Typical prerequisites for admission include passing 10+2 with at least
          50% overall is a minimum requirement.
        </p>
        <p>
          As per regulations, to pursue medicine or engineering in India one
          must have completed Chemistry, Physics, Maths, and Biology with 50% of
          the possible points in Class XII, including practical exams in the
          relevant science subjects.
        </p>
        <p>
          The Government of India has permitted foreign exchange programs for
          foreign nationals to be admitted to Medicine, Dental and Engineering
          colleges.
        </p>
        <h4>List of Documents Required:</h4>
        <p>
          In order to pursue their undergrad courses, candidates must have the
          credentials and documentation listed below. At the time of admission,
          candidates must have both the originals and photocopies of these
          certificates.
        </p>
        <ol>
          <li>Marksheet or Certificate of Class-X.</li>
          <li>Marksheet or Certificate of Class-XII.</li>
          <li>Transcript of Bachelors Degree.</li>
          <li>Study Certificate.</li>
          <li> Transfer Certificate.</li>
        </ol>
        <p>Along with this, Supporting documents like:</p>
        <ol>
          <li>SOP.</li>
          <li>Resume and,</li>
          <li>LORS.</li>
        </ol>
        <p>If required the English language test scores of:</p>
        <ol>
          <li>Not less than 6 for IELTS.</li>
          <li> Not less than 80 for TOEFL.</li>
          <li> Not below than 60 for PTE.</li>
        </ol>
        <Admissions />
        <BackButton />
      </div>
    </>
  );
}
