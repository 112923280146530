import React from "react";
import InrIcon from "../../Assets/img/inr-blue.svg";
import Admissions from "../../Components/Admissions";
import BackButton from "../../Components/BackBtn";

export default function Scholarships() {
  return (
    <>
      <div className="container ptb-5">
        <h6 className="pb-3">Services</h6>
        <div className="d-flex align-items-center gap-4 pb-3">
          <img src={InrIcon} alt="" /> <h3 className="m-0">Scholarships</h3>
        </div>
        <p>
          India is a home to many international students with its numerous
          institutions offering a variety of financial assistances,
          scholarships. An international student will spend approximately $ 8500
          to $ 10,000 for a full academic year in the country.   We assist you
          scholarships in India available from both Government and Private
          Organizations and host institutions. After all scholarship programs
          helps speed up your visa application and ease up the task of health
          insurance and accommodation.
        </p>
        <Admissions />
        <BackButton />
      </div>
    </>
  );
}
