import React from "react";
import VisaIcon from "../../Assets/img/map-blue.svg";
import Admissions from "../../Components/Admissions";
import BackButton from "../../Components/BackBtn";

export default function Visa() {
  return (
    <>
      <div className="container ptb-5">
        <h6 className="pb-3">Services</h6>
        <div className="d-flex align-items-center gap-4 pb-3">
          <img src={VisaIcon} alt="" />{" "}
          <h3 className="m-0">Visa Preparations</h3>
        </div>
        <p>
          We assist you with document preparation and conduct thorough checks to
          ensure everything is accurate for your visa submission.   Student
          visas are granted on the basis of offer letter from recognized
          educational institution.
        </p>
        <p>
          {" "}
          Student visas can be issued for up to five years, or for whatever
          period of the degree programme.
        </p>
        <Admissions />
        <BackButton />
      </div>
    </>
  );
}
