import React, { useState } from "react";
import { IoMenu } from "react-icons/io5";
import { NavLink, Link, useLocation } from "react-router-dom";
import Logo from "../Assets/img/logo.png";
import UGMM from "../Assets/img/ug_m.png";
import PGMM from "../Assets/img/pg_m.png";
import DiplomaMM from "../Assets/img/dp_m.png";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const Navbar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const location = useLocation();

  const menuOptions = [
    { text: "Home", link: "/" },
    { text: "About Us", link: "/about-us" },
    { text: "Why Choose India", link: "/why-choose-india" },
    { text: "Programs", link: "/programs" }, // Programs will have the mega menu
    { text: "Services", link: "/services" },
    { text: "Contact", link: "/contact" },
  ];

  const megaMenu = [
    {
      img: UGMM,
      title: "UG Programs",
      url: "/programs/ug-program",
      list: [
        "Engineering",
        "MBBS",
        "Dental",
        "BAMS",
        "Law",
        "B.Sc (Bachelors)",
        "Arts",
        "Management",
      ], // Add your list items here
    },
    {
      img: PGMM,
      title: "PG Programs",
      url: "/programs/pg-program",
      list: [
        "Engineering",
        "MBBS",
        "Dental",
        "MAMS",
        "Law",
        "M.Sc (Masters)",
        "Arts",
        "Management",
      ],
    },
    {
      img: DiplomaMM,
      title: "Diploma",
      url: "/programs/diploma-course",
      list: [
        "Engineering",
        "Medical",
        "Science",
        "Arts (Diploma)",
        "Arts (PG)",
        "Management",
        "Others",
      ],
    },
  ];
  return (
    <div className="header">
      <nav className="container">
        <div className="nav-logo-container">
          <NavLink to="/">
            <img src={Logo} alt="Logo" />
          </NavLink>
        </div>
        <div className="mob-no">
          <div className="navbar-links-container d-flex justify-content-end align-items-center">
            {menuOptions.map((menu) =>
              menu.text === "Programs" ? (
                <div
                  key={menu.text}
                  className={`programs-menu ${
                    location.pathname.startsWith("/programs") ? "active" : ""
                  }`}
                >
                  <span
                    className="menu-title"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent navigation
                      setShowMegaMenu(true); // Open Mega Menu
                    }}
                  >
                    {menu.text}
                  </span>
                  {showMegaMenu && (
                    <>
                      {/* Overlay to block clicks outside */}
                      <div
                        className="menu-overlay"
                        onClick={() => setShowMegaMenu(false)}
                      ></div>
                      <div
                        className="mega-menu"
                        onMouseLeave={() => setShowMegaMenu(false)} // Close menu on mouse leave
                      >
                        <div className="mega-menu-content">
                          {megaMenu.map((item, index) => (
                            <div className="mega-card" key={index}>
                              <Link
                                to={item.url}
                                className="mega-card-link d-flex gap-2"
                                onClick={() => setShowMegaMenu(false)} // Close menu on click
                              >
                                <img src={item.img} alt={item.title} />
                                <div>
                                  <h5>{item.title}</h5>
                                  {item.list && (
                                    <ul>
                                      {item.list.map((listItem, listIndex) => (
                                        <li key={listIndex}>{listItem}</li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <NavLink
                  key={menu.text}
                  to={menu.link}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  style={{
                    pointerEvents: showMegaMenu ? "none" : "auto", // Disable other links when mega menu is active
                  }}
                >
                  {menu.text}
                </NavLink>
              )
            )}
          </div>
          <button className="themebtn" onClick={props.handleMode}>
            {props.btnIcon}
          </button>
        </div>
        <div className="navbar-menu-container">
          <IoMenu onClick={() => setOpenMenu(true)} className="menu-bar-icon" />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
            className={`mob-nav-area`}
          >
            <List className="mob-nav">
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton>
                    <ListItemText className="men-items">
                      <NavLink to={item.link}>{item.text}</NavLink>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
