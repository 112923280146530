import React from "react";
import { useNavigate } from "react-router-dom";
import BackBtn from "../Assets/img/backbtn.svg";

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page in history
  };

  return (
    <button onClick={handleBack} className="backbtn mt-4">
      <img src={BackBtn} alt="" /> Go Back
    </button>
  );
};

export default BackButton;
