import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import kolkata from "../../Assets/img/destinations/kolkata.png";
import BackButton from "../../Components/BackBtn";

export default function Kolkata() {
  const colleges = [
    "Jadavpur University",
    "University of Calcutta",
    "NUJS",
    "St. Xavier’s College",
    "Indian Institute of Management (IIM)",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Kolkata, India"
            content="Kolkata is city of joy with minimal daily expenses. Student friendly
ecosystem with the century old institutions.Indian Institute of Science
Education and Research (IISER) Kolkata, one of the leading institute in
research studies."
            img={kolkata}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
