import React from "react";
import BackButton from "../Components/BackBtn";
import PopupComponent from "../Components/PopupComponent";

export default function About() {
  return (
    <>
      <PopupComponent />
      <div className="container ptb-5">
        <div className="row justify-content-center">
          <div className="col-md-2">
            <h3>About Us</h3>
          </div>
          <div className="col-md-10">
            <p>
              At <b>LOOK EAST</b> we believe that education is not a Commodity
              it is a transformative experience that opens doors to endless
              opportunities and possibilities. Our founders who were raised in
              India, observed the shortcomings in global education systems and
              cultural misconceptions among students often mislead to wrong
              directions that culminating into waste of time and resources.
            </p>
            <p>
              Having experienced first hand the challenges faced by students and
              families in navigating the complex system in the Western
              Universities, she was inspired to create a consultancy that guides
              students to best universities. What sets us apart is our
              unwavering belief in every students potential. Each child has a
              unique story and we work diligently to understand their
              aspirations, challenges and dreams. Our consultants come from a
              diverse array of educational backgrounds and experiences, allowing
              us to offer a rich tapestry of insights and strategies that
              resonate with our clients.
            </p>
            <p>
              Navigating and making applications to the right universities can
              be overwhelming. From selecting the right program to understanding
              application processes, there are countless challenges along the
              way. We started this venture with a simple idea, to provide
              tailored, accessible support to those seeking quality education.
              Our aim is to guide students through this labyrinth, making their
              dreams to reality.
            </p>
            <p>
              At <b>LOOK EAST</b> we pride ourselves on our promising
              perspective. We combine our diverse backgrounds in education,
              counseling and technology to offer innovative solutions. Our team
              is committed to staying ahead of trends and continually adapting
              to the evolving needs of students. We understand that no two
              journeys are same, each student deserves a personalized approach.
            </p>
          </div>
        </div>
        <BackButton />
      </div>
    </>
  );
}
