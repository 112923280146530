import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

export default function Contact() {
  const contactDetails = {
    mobile: "+91 81233 16030",
    email: ["praveena@v-leed.com", "samhitha@v-leed.com"], // Store emails as an array
    address: "Bangalore, Karnataka, India",
  };

  return (
    <>
      <div className="contact-section">
        <div className="container">
          <h4>Feel free to reach out to us!</h4>
          <ul className="contact-list pt-4">
            <li className="contact-item">
              <FaPhoneAlt />
              <span>
                Mobile:{" "}
                <a href={`tel:${contactDetails.mobile}`}>
                  {contactDetails.mobile}
                </a>
              </span>
            </li>
            <li className="contact-item">
              <FaEnvelope />
              <span>
                Email:{" "}
                {contactDetails.email.map((email, index) => (
                  <span key={index}>
                    <a href={`mailto:${email}`}>{email}</a>
                    {index < contactDetails.email.length - 1 && ", "}
                  </span>
                ))}
              </span>
            </li>
            <li className="contact-item">
              <FaMapMarkerAlt />
              <span>Address: {contactDetails.address}</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
