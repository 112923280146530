import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/logofooter.png";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  const socials = [
    {
      icon: <FaFacebook />,
    },
    {
      icon: <FaInstagram />,
    },
    {
      icon: <FaLinkedinIn />,
    },
    {
      icon: <FaXTwitter />,
    },
  ];

  const general = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/about-us",
      title: "About Us",
    },
    {
      url: "/programs",
      title: "Programs",
    },
    {
      url: "/services",
      title: "Services",
    },
    {
      url: "/",
      title: "Contact",
    },
  ];

  const programs = [
    {
      url: "/programs/ug-program",
      title: "UG Program",
    },
    {
      url: "/programs/pg-program",
      title: "PG Program",
    },
    {
      url: "/programs/diploma-course",
      title: "Diploma",
    },
  ];
  const services = [
    {
      url: "/services/guide-me",
      title: "Guide Me",
    },
    {
      url: "/services/applying-to-institutions",
      title: "Applying to Institutions",
    },
    {
      url: "/services/visa-preparations",
      title: "Visa Preparation",
    },
    {
      url: "/services/scholarships",
      title: "Scholarships",
    },
    {
      url: "/admissions",
      title: "Admissions 2025-26",
    },
  ];
  const whyIndia = [
    {
      url: "/why-choose-india",
      title: "Why Choose India?",
    },
    {
      url: "/",
      title: "Top Destinations",
    },
    {
      url: "/",
      title: "Top Universities",
    },
  ];
  return (
    <>
      <div className="footer ptb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mobcenter mobpb">
              <div>
                <div className="pb-4">
                  <Link to="/">
                    {" "}
                    <img src={Logo} alt="" className="img-fluid" />
                  </Link>
                </div>
                <h6>For Further Details:</h6>
                <ul className="list-unstyled reach">
                  <li>
                    <Link to="tel:918123316030">
                      <MdCall /> <span>+91 81233 16030</span>
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="mailto:praveena@v-leed.com">
                      <FaEnvelope /> <span>praveena@v-leed.edu.in</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto:samhitha@v-leed.com">
                      <FaEnvelope /> <span>samhitha@v-leed.edu.in</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://maps.app.goo.gl/FpSRYxKs3cFjQ5hk8">
                      <FaMapMarkerAlt /> <span>Bangalore, India</span>
                    </Link>
                  </li>
                </ul>
                <div className="socialicons">
                  <div className="">
                    <h6>Follow Us</h6>
                  </div>
                  {socials.map((item, index) => (
                    <>
                      <span key={index}>
                        <Link to="">
                          <i>{item.icon}</i>
                        </Link>
                      </span>
                    </>
                  ))}
                </div>
                <div className="pt-4">
                  <small>
                    © 2024{" "}
                    <Link to="/">
                      <b>Look East.</b>
                    </Link>{" "}
                    All Rights Reserved.
                  </small>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-6 mobcenter mobpb">
              <h6>General</h6>
              <ul className="list-unstyled">
                {general.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-2 col-6 mobcenter mobpb">
              <h6>Programs</h6>
              <ul className="list-unstyled">
                {programs.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-2 col-6 mobcenter mobpb">
              <h6>Services</h6>
              <ul className="list-unstyled">
                {services.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-md-2 col-6 mobcenter mobpb">
              <h6>Why India?</h6>
              <ul className="list-unstyled">
                {whyIndia.map((item) => {
                  return (
                    <li key={item.url}>
                      <Link to={item.url}>{item.title}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
