import React, { useState, useEffect } from "react";
import PopupImg from "../Assets/img/popup.png";

const Popup = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [countries, setCountries] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form behavior
    const formData = {
      firstName: e.target[0].value,
      lastName: e.target[1].value,
      email: e.target[2].value,
      mobile: e.target[3].value,
      country: e.target[4].value,
      message: e.target[5].value,
    };

    try {
      const response = await fetch("http://localhost:5000/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage(true); // Show success message
        sessionStorage.setItem("formSubmitted", "true"); // Save submission in session storage

        setTimeout(() => {
          setSuccessMessage(false); // Hide success message
          onClose(); // Close popup
        }, 3000); // 3-second delay
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  useEffect(() => {
    // Check if the form has been submitted before
    const formSubmitted = sessionStorage.getItem("formSubmitted");
    if (formSubmitted) {
      return; // Do not show the form if it has already been submitted
    }

    // Fetch country data
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryList = data.map((country) => country.name.common);
        setCountries(countryList.sort()); // Sort alphabetically
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();

    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 3000); // 3-second delay for popup visibility

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-box">
        <button className="popup-close" onClick={onClose}>
          &times;
        </button>
        <div className="popup-content">
          {successMessage ? (
            <div className="success-message">
              <h3>Form Submitted Successfully!</h3>
              <p>Thank you for reaching out. We'll get back to you soon.</p>
            </div>
          ) : (
            <>
              <img
                src={PopupImg} // Replace with your image URL
                alt="Graduation"
                className="popup-image"
              />
              <h3>UG/PG/Diploma Admissions are Now Open for 2025-2026 Batch</h3>
              <p>Feel free to connect with us to know more</p>
              <form onSubmit={handleSubmit} className="popup-form">
                <div className="form-group">
                  <input type="text" placeholder="Your First Name" required />
                  <input type="text" placeholder="Your Last Name" required />
                </div>
                <div className="form-group">
                  <input type="email" placeholder="Your Email" required />
                  <input type="text" placeholder="Your Mobile" required />
                  <select required>
                    <option value="">Select Your Country</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
                <textarea
                  placeholder="What are you looking for?"
                  required
                ></textarea>
                <button type="submit">Submit</button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;
