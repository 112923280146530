import React from "react";
import Taj from "../Assets/img/taj.png";
import { Link } from "react-router-dom";
export default function WhyChooseIndia() {
  return (
    <>
      <div className="row ptb-5 justify-content-center">
        <div className="col-md-4 wcindia">
          <h3>Why Choose India</h3>
          <div className="pos-abs">
            <p>
              <i>
                Land of opportunities from immemorial times. The Portuguese, The
                Dutch ,The Arabs, Chinese, The British all came to India to seek
                knowledge and wealth. India can firmly say none were
                disappointed at her own cost.
              </i>
            </p>
            <p>
              <Link to="/why-choose-india">
                <i>Know More...</i>
              </Link>
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <img src={Taj} alt="" className="img-fluid" />
        </div>
      </div>
    </>
  );
}
