import React, { useState, useRef } from "react";

export default function Courses({ courseTitle, courses, popupContent }) {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [popupStyle, setPopupStyle] = useState({});
  const itemsRef = useRef([]);

  const handlePopupOpen = (course, index) => {
    const item = itemsRef.current[index];
    if (item) {
      const rect = item.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      const popupWidth = Math.min(windowWidth * 0.8, 1200); // 80% of screen width or max 1200px
      let left = rect.left + rect.width / 2 - popupWidth / 2;

      if (left < 10) {
        left = 10;
      } else if (left + popupWidth > windowWidth - 10) {
        left = windowWidth - popupWidth - 10;
      }

      setPopupStyle({
        top: rect.bottom + window.scrollY + 5,
        left: left,
        width: `${popupWidth}px`,
      });

      setSelectedCourse(course);
      document.body.classList.add("disable-clicks"); // Disable clicks
    }
  };

  const handlePopupClose = () => {
    setSelectedCourse(null);
    document.body.classList.remove("disable-clicks"); // Re-enable clicks
  };

  return (
    <div className="courses">
      <h4>{courseTitle}</h4>
      <div className="items">
        {courses.map((course, index) => (
          <div
            key={index}
            ref={(el) => (itemsRef.current[index] = el)}
            className={`item ${selectedCourse === course ? "active" : ""}`}
            onClick={() => handlePopupOpen(course, index)}
          >
            {course}
          </div>
        ))}
      </div>

      {selectedCourse && (
        <div className="popup-box" style={popupStyle}>
          <div className="close-icon" onClick={handlePopupClose}>
            &times;
          </div>
          <h5 className="pb-2">{selectedCourse}</h5>
          <div>{popupContent(selectedCourse)}</div>
        </div>
      )}
    </div>
  );
}
