import React from "react";

export default function TopUniversities({ colleges }) {
  return (
    <>
      <h4 className="uni-color">Top Universities</h4>
      <ul className="unilist">
        {colleges.map((college, index) => (
          <li key={index}>{college}</li>
        ))}
      </ul>
    </>
  );
}
