import React from "react";
import Diploma_Course from "../../Assets/img/programs/diploma.png";
import Img1 from "../../Assets/img/ug_bk.png";
import Img2 from "../../Assets/img/pg_bk.png";
import Admissions from "../../Components/Admissions";
import Destinations from "../../Components/Destinations";
import CourseNoPopup from "../../Components/CourseNoPopup";
import ProgramCTA from "../../Components/ProgramCTA";
import BackButton from "../../Components/BackBtn";

export default function Diploma() {
  const engCourses = [
    "Computer Science Engineering (CSE)",
    "Electronics and Communication Engineering (ECE)",
    "Electrical and Electronics Engineering (EEE)",
    "Information Science Engineering (ISE)",
    "Mechanical Engineering (ME)",
    "Chemical Engineering",
    "Civil Engineering",
    "Aeronautical Engineering",
    "Biomedical Engineering",
    "Marine Engineering",
    "Aerospace Engineering",
    "Industrial Engineering",
    "Mechatronics Engineering",
    "Agricultural Engineering",
    "Automobile Engineering",
    "Structural Engineering",
  ];

  const medicalCourses = ["Diploma in Physiotherapy"];

  const scienceCourses = ["Diploma in Computer Applications"];

  const artsCourses = ["Engineering Fine Arts"];

  const artsPGCourses = ["PG Diploma in Yoga"];

  const mngCourses = ["Diploma in Human Resources"];

  const otherCourses = ["Diploma in Textile Design"];

  return (
    <>
      <div className="container ptb-5">
        <h3 className="m-0 pb-4">Diploma Course</h3>
        <img src={Diploma_Course} alt="" className="w-100 pb-5" />
        <CourseNoPopup
          courseTitle="Engineering (Diploma)"
          courses={engCourses}
        />
        <CourseNoPopup courseTitle="Medical" courses={medicalCourses} />
        <CourseNoPopup courseTitle="Science" courses={scienceCourses} />
        <CourseNoPopup courseTitle="Arts (Diploma)" courses={artsCourses} />
        <CourseNoPopup
          courseTitle="Arts (PG Diploma)"
          courses={artsPGCourses}
        />
        <CourseNoPopup courseTitle="Management" courses={mngCourses} />
        <CourseNoPopup courseTitle="Others" courses={otherCourses} />
        <Admissions />
        <Destinations />
        <ProgramCTA
          img1={Img1}
          img1Link="/programs/ug-program"
          title1="Undergraduate (UG) Programs"
          img2={Img2}
          img2Link="/programs/pg-program"
          title2="Postgraduate (PG) Programs"
        />
        <BackButton />
      </div>
    </>
  );
}
