import React from "react";
import HomeAbout from "../Components/HomeAbout";
import WhyChooseIndia from "../Components/WhyChooseIndia";
import Programs from "../Components/Programs";
import CTA from "../Components/CTA";
import Services from "../Components/Services";
import Destinations from "../Components/Destinations";
import PopupComponent from "../Components/PopupComponent";
export default function Home() {
  return (
    <>
      <PopupComponent />
      <div className="hero ptb-7">
        <div className="container">
          <h1>Building World leaders, Intellectuals, Reformers, Legends </h1>
        </div>
      </div>
      <div className="container">
        <HomeAbout />
        <WhyChooseIndia />
      </div>
      <Programs />
      <div className="container">
        <CTA />
      </div>
      <Services />
      <div className="container">
        <Destinations />
      </div>
    </>
  );
}
