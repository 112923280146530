import React from "react";

export default function Admissions() {
  return (
    <>
      <div className="pt-5 pb-4 admissions">
        <div className="row">
          <div className="col-md-12">
            <h5>
                For further details like the dates and deadlines of particular
                university for the application process, and so on.
            </h5>

            <div className="item pb-2">
              <p>
                <b>Contact:</b>
              </p>
              <a href="tel:918123316030">+91 81233 16030</a>
            </div>
            <div className="item align-items-start">
              <p>
                <b>Email:</b>
              </p>
              <div>
                <a href="mailto:praveena@v-leed.edu.in">
                  {" "}
                  praveena@v-leed.edu.in
                </a>{" "}
                <br />
                <a href="mailto:samhitha@v-leed.edu.in">
                  {" "}
                  samhitha@v-leed.edu.in
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
