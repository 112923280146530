import React from "react";
import AdmissionsIcon from "../../Assets/img/admissions.svg";
import Admissions from "../../Components/Admissions";
import BackButton from "../../Components/BackBtn";

export default function ApplyingToInstitutions() {
  return (
    <>
      <div className="container ptb-5">
        <h6 className="pb-3">Services</h6>
        <div className="d-flex align-items-center gap-4 pb-3">
          <img src={AdmissionsIcon} alt="" />{" "}
          <h3 className="m-0">Applying to Institutions</h3>
        </div>
        <p>
          The admission requirements for foreign students in India can vary
          slightly depending on the university and specific programs of study.
          The process of applying will be relatively easy because the acceptance
          procedures at most education institutions are more or less the same.
          The applicant must provide confirmation of their English proficiency
          in order to apply for educational programs in India
        </p>
        <Admissions />
        <BackButton />
      </div>
    </>
  );
}
