import React from "react";

export default function TopDestinations({ title, content, img }) {
  return (
    <>
      <h2 className="pb-3">{title}</h2>
      <img src={img} alt="" className="w-100 pb-4" />
      <p>{content}</p>
    </>
  );
}
