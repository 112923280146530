import React from "react";
import UG from "../Assets/img/ug.png";
import PG from "../Assets/img/pg.png";
import Diploma from "../Assets/img/diploma.png";
import { Link } from "react-router-dom";

export default function Programs() {
  const secCards = [
    {
      url: "/programs/ug-program",
      img: UG,
      title: "Undergraduate (UG) Program",
    },
    {
      url: "/programs/pg-program",
      img: PG,
      title: "Postgraduate (PG) Program",
    },
    {
      url: "/programs/diploma-course",
      img: Diploma,
      title: "Diploma Courses",
    },
  ];
  return (
    <>
      <div className="container programs">
        <div className="bgSec ptb-5">
          <h3>Programs</h3>
          <div className="row seccards pt-4">
            {secCards.map((item, index) => (
              <div className="col-md-4" key={index}>
                <Link to={item.url}>
                  <div className="item text-center">
                    <img src={item.img} alt="" className="img-fluid" />
                    <div className="head">
                      <p>{item.title}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
