import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import chennai from "../../Assets/img/destinations/chennai.png";
import BackButton from "../../Components/BackBtn";

export default function Chennai() {
  const colleges = [
    "Indian Institute of Technology, Madras",
    "Anna university",
    "University of Madras",
    "Sathyabhama Institute of Science and Technology",
    "Chennai Mathematical Institute",
    "VIT University",
    "Alagappa University",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Chennai, India"
            content="The state has a lot to offer to international students it is definitely one of the
state to be considered because of the quality of the institutions. There are
wide range of choices with more than 18 top universities. Almost 3500
students get enrolled every year."
            img={chennai}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
