import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import mumbai from "../../Assets/img/destinations/mumbai.png";
import BackButton from "../../Components/BackBtn";

export default function Mumbai() {
  const colleges = [
    "Indian Institute of Technology Bombay",
    "Savitribai Phule Pune University",
    "Tata Institute of Fundamental Research",
    "University of Mumbai",
    "Tata Institute of Social Sciences",
    "Maharashtra University of Health Sciences",
    "Narsee Monjee Institute of Management and Higher Studies",
    "Maharashtra National Law University, Nagpur",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Mumbai, India"
            content="The innovation led educational hub of India.
Pune once was the prominent city of Maratha empire which is considered
as the educational hub and is considered as the Oxford of the
east. Mumbai is another city that is making its mark among foreign
students, though it is still a far cry compared to Pune with few
international students enrolled in Mumbai University."
            img={mumbai}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
