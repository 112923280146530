import React from "react";
import { useState } from "react";
import Popup from "./Popup";

export default function PopupComponent() {
  const [showPopup, setShowPopup] = useState(true);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return <div>{showPopup && <Popup onClose={handleClosePopup} />}</div>;
}
