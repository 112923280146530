import React from "react";
import Guide from "../Assets/img/guideme.svg";
import Apply from "../Assets/img/apply.svg";
import Visa from "../Assets/img/india.svg";
import Scholarships from "../Assets/img/inr.svg";
import { Link } from "react-router-dom";

export default function Services() {
  const secCards = [
    {
      url: "/services/guide-me",
      img: Guide,
      title: "Guide Me",
    },
    {
      url: "/services/applying-to-institutions",
      img: Apply,
      title: "Applying to Institutions",
    },
    {
      url: "/services/visa-preparations",
      img: Visa,
      title: "Visa Preparations",
    },
    {
      url: "/services/scholarships",
      img: Scholarships,
      title: "Scholarships",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="services ptb-5">
          <h3>Services</h3>
          <p className="pt-3">
            Our services encompass academic counselling, application assistance,
            career coaching and much more, all designed to empower our clients
            through knowledge and resources.
          </p>
          <div className="row pt-4">
            {secCards.map((item, index) => (
              <div className="col-md-3 col-6" key={index}>
                <Link to={item.url}>
                  <div className="item">
                    <div className="text-center">
                      <img src={item.img} alt="" className="pb-3" />
                      <p>{item.title}</p>
                    </div>
                    <p className="link">Know More</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
