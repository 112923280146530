import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import BLR from "../../Assets/img/destinations/blr.png";
import BackButton from "../../Components/BackBtn";

export default function Bangalore() {
  const colleges = [
    "Manipal University",
    "Bangalore University",
    "Visveshvaraya Technological University (VTU)",
    "Mysore University",
    "National Law School of India University (NLU)",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Bangalore, India"
            content="Karnataka stands out as the leading state in India for international students, with more than 12,000 enrolled. The main factors contributing to its appeal are cities like Bangalore and Manipal. A report from the Association of Indian Universities highlights that Manipal University has one of the highest numbers of foreign students, particularly those from Gulf and African nations, who come to the state for courses in medicine and engineering. In fact, Karnataka has the highest enrollment of foreign students across the country, the majority of whom are from Malaysia. Diana Britto from Manipal University noted that there has been a noticeable increase in the diversity of students in recent years, with many nationalities represented. Mysore is another city experiencing growth in international enrollment, with student numbers exceeding 1,000. THE NATIONAL LAW SHOOL BANGLORE IS CONSIDERED AS HARVARD OF EAST."
            img={BLR}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
