import React from "react";
import PGP from "../../Assets/img/programs/pgp.png";
import Img1 from "../../Assets/img/ug_bk.png";
import Img2 from "../../Assets/img/dp_bk.png";
import Admissions from "../../Components/Admissions";
import Destinations from "../../Components/Destinations";
import Courses from "../../Components/Courses";
import ProgramCTA from "../../Components/ProgramCTA";
import BackButton from "../../Components/BackBtn";

export default function PGPrograms() {
  const engCourses = [
    "Computer Science Engineering (CSE)",
    "Electronics and Communication Engineering (ECE)",
    "Electrical and Electronics Engineering (EEE)",
    "Information Science Engineering (ISE)",
    "Mechanical Engineering (ME)",
    "Chemical Engineering",
    "Civil Engineering",
    "Aeronautical Engineering",
    "Biomedical Engineering",
    "Marine Engineering",
    "Aerospace Engineering",
    "Industrial Engineering",
    "Mechatronics Engineering",
    "Agricultural Engineering",
    "Automobile Engineering",
    "Structural Engineering",
  ];
  const engPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>2 Years/ 4 Semesters.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance Exam (PG CET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 4 Years of B.E.,/BTech from a
            recognized college with a minimum of 50%.
          </li>
          <li>
            As a result, each University has different requirements for
            Admission (Entrance Exams).
          </li>
        </ol>
      </>
    );
  };

  const medicineCourses = ["Doctor of Medicine (MD)", "Master of Surgery (MS)"];
  const medicinePopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (NEET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 5.5 Years with Compulsory Internship
            from a recognized medical universities.
          </li>
        </ol>
      </>
    );
  };

  const dentalCourses = ["Master of Dental Surgery (MDS)"];
  const dentalPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (NEET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 5 Years with Compulsory Internship
            from a recognized medical universities.
          </li>
        </ol>
      </>
    );
  };

  const mamsCourses = ["Master of Ayurvedic Medicine and Surgery (MAMS)"];
  const mamsPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Merit Based.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 5 Years 6 Months BAMS from a
            recognized university.
          </li>
        </ol>
      </>
    );
  };

  const lawCourses = ["LLM"];
  const lawPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>2 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (CLAT) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 3 Years of LLB or equivalent from a
            recognized University.
          </li>
        </ol>
      </>
    );
  };

  const mscCourses = [
    "MSc in Paramedical Technology",
    "MSc Audiology",
    "MSc Agricultural Science",
    "MSc in Applied Biotechnology",
    "MSc in Biochemistry",
    "MSc in Microbiology (Non-Clinical)",
    "MSc in Cardiac Technology",
    "MSc in Cardiovascular Technology",
    "MSc in Nutrition and Dietetics",
    "MSc in Genetics",
    "MSc Agriculture",
    "MSc Information Technology",
    "MSc Applied Chemistry",
    "MSc Nursing",
    "MSc in Biological Sciences",
    "MSc Applied Mathematics",
    "MSc Sericulture",
    "MSc Botany",
    "MSc Biological Sciences",
    "MSc Environmental Sciences",
    "MSc Ecology",
    "MSc Applied Electronics",
    "MSc Clinical Nutrition",
    "MSc Forensic Sciences",
    "MSc Science and Nutrition",
    "MSc Psychology",
  ];
  const mscPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>2 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>
              Aspirants must have completed their 10+2+ 3 years B.Sc from a
              recognized board.
            </b>
          </div>
        </div>
        <ol>
          <li>
            Aspirants should have secured a minimum of 50% aggregate marks.
          </li>
          <li>
            Candidates must have studied 3 Years B.Sc from recognized colleges.
          </li>
        </ol>
      </>
    );
  };

  const artsCourses = [
    "Masters in Animation",
    "Masters in Fashion Design",
    "Masters in Textile Design",
    "Masters in Digital Film Making",
    "Masters in Animation and Visual Effects",
    "Masters in Animation and Film Making",
    "Masters in Animation and Game Design Development",
    "Masters in Cinematography",
    "Masters in Yoga/Advanced",
    "Masters in Communication Design",
    "Masters of Commerce (B. Com)",
    "Masters in Economics",
    "Masters in Physiology",
    "Masters in Public Administration",
    "Masters in Accountancy",
    "Masters in Interior and Architecture Design",
    "Masters in Mass Communication and Journalism",
    "Masters in Finance",
  ];
  const artsPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>2 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>
              Candidates must have completed their 3 Years Arts from a
              recognized colleges.
            </b>
          </div>
        </div>
        <ol>
          <li>
            Candidates should have secured a minimum of 50% aggregate marks.
          </li>
        </ol>
      </>
    );
  };

  const mngCourses = [
    "Masters in Business Administration (BBA)",
    "Masters in Hospitality and Tourism",
    "Masters in Hospitality Management",
    "Masters in Hospitality and Hotel Administration",
  ];
  const mngPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>2 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Merit Based.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 3 Years of Management Course from a
            recognized universities.
          </li>
        </ol>
      </>
    );
  };

  return (
    <>
      <div className="container ptb-5">
        <h3 className="m-0 pb-4">Postgraduate (PG) Programs</h3>
        <img src={PGP} alt="" className="w-100 pb-5" />
        <Courses
          courseTitle="Engineering (M.E./MTech)"
          courses={engCourses}
          popupContent={engPopupContent}
        />
        <Courses
          courseTitle="Medicine"
          courses={medicineCourses}
          popupContent={medicinePopupContent}
        />
        <Courses
          courseTitle="Dental"
          courses={dentalCourses}
          popupContent={dentalPopupContent}
        />
        <Courses
          courseTitle="MAMS"
          courses={mamsCourses}
          popupContent={mamsPopupContent}
        />
        <Courses
          courseTitle="Law"
          courses={lawCourses}
          popupContent={lawPopupContent}
        />
        <Courses
          courseTitle="Masters of Sciences (M.Sc)"
          courses={mscCourses}
          popupContent={mscPopupContent}
        />
        <Courses
          courseTitle="Arts"
          courses={artsCourses}
          popupContent={artsPopupContent}
        />
        <Courses
          courseTitle="Management"
          courses={mngCourses}
          popupContent={mngPopupContent}
        />
        <Admissions />
        <Destinations />
        <ProgramCTA
          img1={Img1}
          img1Link="/programs/ug-program"
          title1="Undergraduate (UG) Programs"
          img2={Img2}
          img2Link="/programs/diploma-course"
          title2="Diploma Courses"
        />
        <BackButton />
      </div>
    </>
  );
}
