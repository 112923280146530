import React from "react";
import Guide from "../../Assets/img/guide-blue.svg";
import Admissions from "../../Components/Admissions";
import BackButton from "../../Components/BackBtn";

export default function GuideMe() {
  return (
    <>
      <div className="container ptb-5">
        <h6 className="pb-3">Services</h6>
        <div className="d-flex align-items-center gap-4 pb-3">
          <img src={Guide} alt="" /> <h3 className="m-0">Guide Me</h3>
        </div>
        <p>
          We begin by assessing your individual profile and needs to assist you
          in identifying the most suitable destination, course, and institution
          that align with your eligibility, interests, and aspirations. We
          supply you with all essential information regarding institutions that
          provide programs tailored to your specifications and also facilitate
          your application to these colleges, schools, or universities in an
          impartial manner. The admissions facilitated by our organizations are
          exclusively directed towards reputable colleges authorized to operate
          under the prevailing legal regulations.
        </p>
        <Admissions />
        <BackButton />
      </div>
    </>
  );
}
