import React from "react";
import UGP from "../../Assets/img/programs/ugp.png";
import Img1 from "../../Assets/img/pg_bk.png";
import Img2 from "../../Assets/img/dp_bk.png";
import Admissions from "../../Components/Admissions";
import Destinations from "../../Components/Destinations";
import Courses from "../../Components/Courses";
import ProgramCTA from "../../Components/ProgramCTA";
import BackButton from "../../Components/BackBtn";

export default function UGPrograms() {
  const engCourses = [
    "Computer Science Engineering (CSE)",
    "Electronics and Communication Engineering (ECE)",
    "Electrical and Electronics Engineering (EEE)",
    "Information Science Engineering (ISE)",
    "Mechanical Engineering (ME)",
    "Chemical Engineering",
    "Civil Engineering",
    "Aeronautical Engineering",
    "Biomedical Engineering",
    "Marine Engineering",
    "Aerospace Engineering",
    "Industrial Engineering",
    "Mechatronics Engineering",
    "Agricultural Engineering",
    "Automobile Engineering",
    "Structural Engineering",
  ];
  const engPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>4 Years/ 8 Semesters.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance Exam (CET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 65-75% with Physics, Chemistry, and Mathematics as
            their compulsory subjects. And English as a mandatory subject.
          </li>
          <li>
            Eligibility for Specific Streams: Candidates are also qualified for
            some engineering courses, such as biotechnology engineering,
            biomedical engineering, etc., if biology was one of their subjects
            in Class 12.
          </li>
          <li>
            Each university has different requirements for Admission (Entrance
            Exams).
          </li>
        </ol>
      </>
    );
  };

  const mbbsCourses = ["Bachelor of Medicine and Bachelor of Surgery"];
  const mbbsPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>5.5 Years with 1 Year Compulsory Internship.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (NEET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 50-60% with Physics, Chemistry, and biology as
            their compulsory subjects. And English as a mandatory subject.
          </li>
        </ol>
      </>
    );
  };

  const dentalCourses = ["Bachelor of Dental Surgery (BDS)"];
  const dentalPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>4+1 Years with Compulsory Internship.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (NEET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 50-60% with Physics, Chemistry, and biology as
            their compulsory subjects. And English as a mandatory subject.
          </li>
        </ol>
      </>
    );
  };

  const bamsCourses = ["Bachelor of Ayurvedic Medicine and Surgery (BAMS)"];
  const bamsPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>5 Years 6 Months.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (NEET) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 50-60% with Physics, Chemistry, and biology as
            their compulsory subjects. And English as a mandatory subject.
          </li>
        </ol>
      </>
    );
  };

  const lawCourses = ["LLB", "LLB (Hons)"];
  const lawPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 / 5 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Entrance exam (CLAT) and Merit.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 50-60% in any stream. And English as a mandatory
            subject.
          </li>
        </ol>
      </>
    );
  };

  const bscCourses = [
    "BSc in Paramedical Technology",
    "BSc Audiology",
    "BSc Agricultural Science",
    "BSc in Applied Biotechnology",
    "BSc in Biochemistry",
    "BSc in Microbiology (Non-Clinical)",
    "BSc in Cardiac Technology",
    "BSc in Cardiovascular Technology",
    "BSc in Nutrition and Dietetics",
    "BSc in Genetics",
    "BSc Agriculture",
    "Bsc Information Technology",
    "Bsc Applied Chemistry",
    "BSc Nursing",
    "Bsc in Biological Sciences",
    "BSc Applied Mathematics",
    "BSc Botany",
    "BSc Biological Sciences",
    "BSc Environmental Sciences",
    "BSc Ecology",
    "BSc Applied Electronics",
    "BSc Clinical Nutrition",
    "BSc Forensic Sciences",
    "BSc Science and Nutrition",
    "BSc Psychology",
    "BSc Sericulture",
    "BSc in Pharmacy",
    "Bachelor of Physiotherapy",
  ];
  const bscPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>
              Aspirants must have completed their 10+2 from a recognized board.
            </b>
          </div>
        </div>
        <ol>
          <li>
            Aspirants should have secured a minimum of 50% aggregate marks.
          </li>
          <li>
            Candidates must have studied Science Stream in their Class 12.
          </li>
          <li>
            Candidates from other streams can also join in Bachelor of Science,
            if the particular University or College grants the admission.
          </li>
        </ol>
      </>
    );
  };

  const artsCourses = [
    "Bachelor in Animation",
    "Bachelor in Fashion Design",
    "Bachelor in Textile Design",
    "Bachelor in Digital Film Making",
    "Bachelor in Animation and Visual Effects",
    "Bachelor in Animation and Film Making",
    "Bachelor in Animation and Game Design Development",
    "Bachelor in Cinematography",
    "Bachelor in Yoga/Advanced",
    "Bachelor in Communication Design",
    "Bachelor of Commerce (B. Com)",
    "Bachelor in Economics",
    "Bachelor in Physiology",
    "Bachelor in Public Administration",
    "Bachelor in Interior and Architecture Design",
    "Bachelor in Mass Communication and Journalism",
    "Bachelor in English",
    "Bachelor in Finance",
    "Bachelor in Accountancy",
  ];
  const artsPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>
              Candidates must have completed their 10+2 from a recognized board.
            </b>
          </div>
        </div>
        <ol>
          <li>
            Candidates should have secured a minimum of 50% aggregate marks.
          </li>
        </ol>
      </>
    );
  };

  const mngCourses = [
    "Bachelor in Business Administration (BBA)",
    "Bachelor in Hospitality and Tourism",
    "Bachelor in Hospitality Management",
    "Bachelor in Hospitality and Hotel Administration",
  ];
  const mngPopupContent = (course) => {
    return (
      <>
        <h6 className="pb-2">Eligibility:</h6>
        <div className="items">
          <div className="item">Duration</div>
          <div>
            <strong>3 Years.</strong>
          </div>
        </div>
        <div className="items">
          <div className="item">Admission Criteria</div>
          <div>
            <b>Merit Based.</b>
          </div>
        </div>
        <ol>
          <li>
            Candidates must have completed 10+2/ 12th from a recognized board
            with a minimum of 50-60% in any stream. And English as a mandatory
            subject.
          </li>
        </ol>
      </>
    );
  };

  return (
    <>
      <div className="container ptb-5">
        <h3 className="m-0 pb-4">Undergraduate (UG) Programs</h3>
        <img src={UGP} alt="" className="w-100 pb-5" />
        <Courses
          courseTitle="Engineering (B.E./BTech)"
          courses={engCourses}
          popupContent={engPopupContent}
        />
        <Courses
          courseTitle="MBBS"
          courses={mbbsCourses}
          popupContent={mbbsPopupContent}
        />
        <Courses
          courseTitle="Dental"
          courses={dentalCourses}
          popupContent={dentalPopupContent}
        />
        <Courses
          courseTitle="BAMS"
          courses={bamsCourses}
          popupContent={bamsPopupContent}
        />
        <Courses
          courseTitle="Law"
          courses={lawCourses}
          popupContent={lawPopupContent}
        />
        <Courses
          courseTitle="Bachelor of Sciences (B.Sc)"
          courses={bscCourses}
          popupContent={bscPopupContent}
        />
        <Courses
          courseTitle="Arts"
          courses={artsCourses}
          popupContent={artsPopupContent}
        />
        <Courses
          courseTitle="Management"
          courses={mngCourses}
          popupContent={mngPopupContent}
        />
        <Admissions />
        <Destinations />
        <ProgramCTA
          img1={Img1}
          img1Link="/programs/pg-program"
          title1="Postgraduate (PG) Programs"
          img2={Img2}
          img2Link="/programs/diploma-course"
          title2="Diploma Courses"
        />
        <BackButton />
      </div>
    </>
  );
}
