import "./App.css";
import "./Assets/styles/custom.scss";
import "./Assets/styles/responsive.scss";
import "./Assets/styles/popup.css";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import Programs from "./Components/Programs";
import About from "./Pages/About";
import WhyIndia from "./Pages/WhyIndia";
import Services from "./Components/Services";
import Bangalore from "./Pages/TopDestinations/Bangalore";
import Hyderabad from "./Pages/TopDestinations/Hyderabad";
import Kolkata from "./Pages/TopDestinations/Kolkata";
import Mumbai from "./Pages/TopDestinations/Mumbai";
import Delhi from "./Pages/TopDestinations/Delhi";
import Chennai from "./Pages/TopDestinations/Chennai";
import AdmissionsView from "./Pages/AdmissionsView";
import GuideMe from "./Pages/Services/GuideMe";
import ApplyingToInstitutions from "./Pages/Services/ApplyingToInstitutions";
import Visa from "./Pages/Services/Visa";
import Scholarships from "./Pages/Services/Scholarships";
import UGPrograms from "./Pages/Programs/UGPrograms";
import PGPrograms from "./Pages/Programs/PGPrograms";
import Diploma from "./Pages/Programs/Diploma";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/why-choose-india" element={<WhyIndia />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />

          {/* Top Destinations */}
          <Route path="/top-destinations/bangalore" element={<Bangalore />} />
          <Route path="/top-destinations/chennai" element={<Chennai />} />
          <Route path="/top-destinations/hyderabad" element={<Hyderabad />} />
          <Route path="/top-destinations/kolkata" element={<Kolkata />} />
          <Route path="/top-destinations/mumbai" element={<Mumbai />} />
          <Route path="/top-destinations/delhi" element={<Delhi />} />

          <Route path="/admissions" element={<AdmissionsView />} />

          {/* Services */}
          <Route path="/services/guide-me" element={<GuideMe />} />
          <Route
            path="/services/applying-to-institutions"
            element={<ApplyingToInstitutions />}
          />
          <Route path="/services/visa-preparations" element={<Visa />} />
          <Route path="/services/scholarships" element={<Scholarships />} />

          {/* Programs */}
          <Route path="/programs/ug-program" element={<UGPrograms />} />
          <Route path="/programs/pg-program" element={<PGPrograms />} />
          <Route path="/programs/diploma-course" element={<Diploma />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
