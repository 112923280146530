import React from "react";

export default function CourseNoPopup({ courseTitle, courses}) {
  return (
    <div className="courses nopopup">
      <h4>{courseTitle}</h4>
      <div className="items">
        {courses.map((course, index) => (
          <div className="item" key={index}>
            {course}
          </div>
        ))}
      </div>
    </div>
  );
}
