import React from "react";
import BNG_img from "../Assets/img/bng.png";
import HBD_img from "../Assets/img/hyd.png";
import Chennai_img from "../Assets/img/chennai.png";
import Kolkata_img from "../Assets/img/kolkata.png";
import Mumbai_img from "../Assets/img/mumbai.png";
import Delhi_img from "../Assets/img/delhi.png";
import Arrow from "../Assets/img/arrow.svg";
import { Link } from "react-router-dom";

export default function Destinations() {
  const secCards = [
    {
      url: "/top-destinations/bangalore",
      img: BNG_img,
      title: "Bangalore",
    },
    {
      url: "/top-destinations/delhi",
      img: Delhi_img,
      title: "Delhi",
    },
    {
      url: "/top-destinations/mumbai",
      img: Mumbai_img,
      title: "Mumbai",
    },
    {
      url: "/top-destinations/hyderabad",
      img: HBD_img,
      title: "Hyderabad",
    },
    {
      url: "/top-destinations/kolkata",
      img: Kolkata_img,
      title: "Kolkata",
    },
    {
      url: "/top-destinations/chennai",
      img: Chennai_img,
      title: "Chennai",
    },
  ];
  return (
    <>
      <div className="bgSec destinations ptb-5">
        <h3>Know your top destinations in India</h3>
        <div className="row seccards pt-4">
          {secCards.map((item, index) => (
            <div className="col-md-2 col-6" key={index}>
              <Link to={item.url}>
                <div className="item text-center">
                  <img src={item.img} alt="" className="img-fluid" />
                  <div className="head">
                    <p className="dest">{item.title}</p>
                    <img src={Arrow} alt="" className="arw" />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
