import React from "react";
import TopDestinations from "../../Components/TopDestinations";
import TopUniversities from "../../Components/TopUniversities";
import CTA from "../../Components/CTA";
import Destinations from "../../Components/Destinations";
import Admissions from "../../Components/Admissions";
import delhi from "../../Assets/img/destinations/delhi.png";
import BackButton from "../../Components/BackBtn";

export default function Delhi() {
  const colleges = [
    "Jawaharlal Nehru University (JNU)",
    "Delhi University",
    "South Asian University",
    "Lady Sriram College for Women Delhi",
    "Jamia Millia Islamia (JMI) New Delhi",
    "Hindu College (Delhi university)",
    "St. Stephen’s College, Delhi",
  ];
  return (
    <>
      <div className="container">
        <div className="ptb-5">
          <TopDestinations
            title="Delhi, India"
            content="The capital city of INDIA. It is continued to be the preferred destination for
foreign students. Nearly 2000 students get enrolled in DELHI university alone.
Economics , English, history, science are the top subjects chosen by most
of the students. THE professional courses are opted mainly by the
students from the SAARC COUNTIRES."
            img={delhi}
          />
          <TopUniversities colleges={colleges} />
          <CTA />
          <Destinations />
          <Admissions />
          <BackButton />
        </div>
      </div>
    </>
  );
}
