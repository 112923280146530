import React from "react";
import Button from "./Button";
export default function HomeAbout() {
  return (
    <>
      <div className="row ptb-5 justify-content-center">
        <div className="col-md-2">
          <h3>About Us</h3>
        </div>
        <div className="col-md-10">
          <p>
            At <b>LOOK EAST</b> we believe that education is not a Commodity it
            is a transformative experience that opens doors to endless
            opportunities and possibilities. Our founders who were raised in
            India, observed the shortcomings in global education systems and
            cultural misconceptions among students often mislead to wrong
            directions that culminating into waste of time and resources.
          </p>
          <Button url="/about-us" title="Know More" className="pt-3 mt-2" />
        </div>
      </div>
    </>
  );
}
