import React from "react";
import Taj from "../Assets/img/taj.png";
import IND from "../Assets/img/ind.svg";
import Culture from "../Assets/img/culture.png";
import Destinations from "../Components/Destinations";
import BackButton from "../Components/BackBtn";

export default function WhyIndia() {
  return (
    <>
      <div className="container ptb-5">
        <div className="row justify-content-center">
          <div className="col-md-4 wcindia">
            <h3>Why Choose India</h3>
            <div className="pos-abs">
              <p>
                <i>
                  Land of opportunities from immemorial times. The Portuguese,
                  The Dutch ,The Arabs, Chinese, The British all came to India
                  to seek knowledge and wealth. India can firmly say none were
                  disappointed at her own cost.
                </i>
              </p>
            </div>
          </div>
          <div className="col-md-8">
            <img src={Taj} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="row ptb-5 justify-content-center align-items-center">
          <div className="col-md-4 wcindia">
            <img src={IND} alt="" className="img-fluid w-100" />
          </div>
          <div className="col-md-8">
            <p>
              The contribution of ZERO in the world of numericals, PI in
              Astrophysics laid the foundation in the development of science and
              technology. Even before the thirst for the development of science 
              in the western world, this land showed the way to attain eternity
              and nirvana through the VEDAS and UPANISHADS making the world
              better for the human being.
            </p>
            <p>
              Today world over, several leading companies are led by leaders of
              Indian origin graduated from Indian Universities highlights the
              greatness of Indian Universities and Institutions. Pursue a
              degree, collaborate with outstanding scholars, and immerse
              yourself in an environment that is truly unmatched.
            </p>
            <p>
              The modern world has received the greats of Gandhi, Ambedkar ,
              Rabindranath Tagore, C.V. Raman, Jagadish Chandra Bose to the
              recent Sundar Pichai, Satya Nadella, Indra Nooyi, Amartya Sen,
              Shakuntala Devi, Leena Nair, Ratan Tata, Charles Correa, Abhijeeth
              Banerjee, Geetha Gopinath and many more.
            </p>
          </div>
        </div>
        <div className="row pb-4 justify-content-center align-items-center">
          <div className="col-md-8">
            <p>
              India is a country of <b>unity with diversity</b>. Multicultural,
              no racism, embracing universal brotherhood is expressed in more
              than 3000 vernacular languages, student friendly with no communal
              tensions/ war with any of its neighboring states. Providing
              employment and education to the largest populated country in the
              world with 98,374 INR - the per capita net national income,
              growing GDP 7.0%.
            </p>
            <p>
              With worker population ratio 56.0%, with declining at the
              unemployment rate of 3.2%, Student friendly policies, very low
              cost of living and affordable tuition fees. The Universities are
              decorated by renowned professors creating highest number of
              engineers, doctors and other professionals compare to any other
              country.
            </p>
          </div>
          <div className="col-md-4 wcindia">
            <img src={Culture} alt="" className="img-fluid w-100" />
          </div>
        </div>
        <Destinations />
        <BackButton />
      </div>
    </>
  );
}
