import React from "react";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="ptb-5">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="cta px-2 ptb-5">
              <p className="font-18">Begin an exciting academic journey!</p>
              <p className="cursive">
                UG/PG/Diploma Admissions are Now Open for{" "}
                <strong>2025-2026</strong> Batch.
              </p>
              <Button url="/admissions" title="Know More" className="pt-4" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
